import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getBranches } from "../../store/about/aboutAction";
import Loader from "../../component/Loader";
import { Link } from "react-router-dom";
import bgserv from "../../assets/intro.png";
import LastWork from "./LastWork";

const Services = () => {
  // const { t } = useTranslation();
  // const dispatch = useDispatch();
  // const lan = useSelector((state) => state.LanSlicer.language);
  // const { branches, loading } = useSelector((state) => state.aboutSlicer);

  // useEffect(() => {
  //   dispatch(getBranches(lan));
  // }, [dispatch, lan]);

  return (
    <Fragment>
      <LastWork />
      <section id="services" className="scrollspy-section padding-large">
        <div className="container">
          <div className="row">
            <div className="section-header col-12">
              <div className="title">
                <span>what we do</span>
              </div>
              <h2 className="section-title text-title">Services</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="services-item">
                <span className="number">01</span>
                <h3>Architecture Portfolio</h3>
                <p>
                  Lectus molestie id enim ipsum. Netus sed cursus nibh iaculis
                  ipsum turpis nulla blandit dui.
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="services-item">
                <span className="number">02</span>
                <h3>Planning Process</h3>
                <p>
                  Lectus molestie id enim ipsum. Netus sed cursus nibh iaculis
                  ipsum turpis nulla blandit dui.
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="services-item">
                <span className="number">03</span>
                <h3>Unique Design</h3>
                <p>
                  Lectus molestie id enim ipsum. Netus sed cursus nibh iaculis
                  ipsum turpis nulla blandit dui.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="testimonial" className="padding-large">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <figure>
                <img src={bgserv} alt="review" className="review-image" />
              </figure>
            </div>

            <div className="col-md-6">
              <div className="testimonial-block">
                <div className="section-header">
                  <div className="title">
                    <span>what clients says</span>
                  </div>
                  <h2 className="section-title text-title">Testimonials</h2>
                </div>
                <div className="testimonials-inner">
                  <q>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Placerat venenatis tempor, turpis dolor. Aliquam faucibus
                    velit, volutpat vulputate risus urna enim.
                  </q>
                  <div className="testimonial-author">
                    <div className="author-detail">
                      <div className="name">Lucifer</div>
                      <div className="author-title">CEO, unity real state</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Services;
