import React from "react";
import bgserv from "../../assets/projects/2.jpg";
const LastWork = () => {
  return (
    <section className="section section-lg bg-default">
      <div className="container">
        <div className="row row-30">
          <div className="col-lg-6">
            <img
              src={bgserv}
              alt=""
              width="526"
              height="452"
              className="imgTopServ"
            />
          </div>
          <div className="col-lg-6">
            <h3 className="custom-title offset-left-14 text-title">
              Results of our work
            </h3>
            <p className="big text-black">
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <p className="big text-black">
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco
            </p>
            <article className="quote-simple">
              <div className="quote-simple-body">
                <q className="text-primary">
                  Morbi tristique senectus et netus et malesuada fames ac turpis
                  egestas. Vestibulum tortor.
                </q>
              </div>
              <div className="quote-simple-footer">
                <cite className="heading-5 quote-simple-cite">Jane Smith</cite>
                <span className="quote-simple-description">Client</span>
              </div>
            </article>
            <div className="row row-3">
              <div className="col-md-6">
                <ul className="list-marked list-marked-3">
                  <li>
                    <span className="big">Consectetur adipiscing elit</span>
                  </li>
                  <li>
                    <span className="big">Quis nostrud exercitation</span>
                  </li>
                  <li>
                    <span className="big">Exercitation ullamco laboris</span>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul className="list-marked list-marked-3">
                  <li>
                    <span className="big">Pharetra vel turpis nunc</span>
                  </li>
                  <li>
                    <span className="big">Enim eu turpis egestas</span>
                  </li>
                  <li>
                    <span className="big">Nunc mi ipsum faucibus vitae</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LastWork;
