import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { gettotalOffers } from "../../store/totaloffer/totaloffAction";
import { Link } from "react-router-dom";
import { baseUrl } from "../../utils/APi";
import { LazyLoadImage } from "react-lazy-load-image-component";
import bg from "../../assets/icon/light/2.png";

const WhyUs = () => {
  // const dispatch = useDispatch();
  // const lan = useSelector((state) => state.LanSlicer.language);
  // const { offers } = useSelector((state) => state.Offers);

  // useEffect(() => {
  //   if (offers?.length === 0 ) {
  //    const promise= dispatch(gettotalOffers(lan));
  //     return () => {
  //       promise.abort();
  //     };
  //   }
  // }, [lan, dispatch,offers?.length]);

  return (
    <section className="section section-lg bg-gray-700 text-center text-sm-left">
      <div className="container">
        <div className="row row-40">
          <div className="col-lg-9">
            <div className="row row-30 row-xxl-85">
              <div className="col-sm-6 col-md-4">
                <h5>Architecture</h5>
                <ul className="list-xs font-weight-regular">
                  <li>
                    <a className="link-item" href="#">
                      Planning & Development
                    </a>
                  </li>
                  <li>
                    <a className="link-item" href="#">
                      Project Management
                    </a>
                  </li>
                  <li>
                    <a className="link-item" href="#">
                      Structural Engineering
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 col-md-4">
                <h5>3D Modeling</h5>
                <ul className="list-xs font-weight-regular">
                  <li>
                    <a className="link-item" href="#">
                      Interior Rendering
                    </a>
                  </li>
                  <li>
                    <a className="link-item" href="#">
                      Visualization
                    </a>
                  </li>
                  <li>
                    <a className="link-item" href="#">
                      VR Architecture
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 col-md-4">
                <h5>Design</h5>
                <ul className="list-xs font-weight-regular">
                  <li>
                    <a className="link-item" href="#">
                      Interior Design
                    </a>
                  </li>
                  <li>
                    <a className="link-item" href="#">
                      Architectural Design
                    </a>
                  </li>
                  <li>
                    <a className="link-item" href="#">
                      Technical Detailing
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 col-md-4">
                <article className="box-counter">
                  <div className="box-counter-main">
                    <div className="counter">10</div>
                    <span className="small small_top">+</span>
                  </div>
                  <div className="box-counter-title">International awards</div>
                </article>
              </div>
              <div className="col-sm-6 col-md-4">
                <article className="box-counter">
                  <div className="box-counter-main">
                    <div className="counter">57</div>
                  </div>
                  <div className="box-counter-title">Finished projects</div>
                </article>
              </div>
              <div className="col-sm-6 col-md-4">
                <article className="box-counter">
                  <div className="box-counter-main">
                    <div className="counter">19</div>
                  </div>
                  <div className="box-counter-title">Years of experience</div>
                </article>
              </div>
            </div>
          </div>
          <div className="col-lg-3 d-flex flex-column justify-content-between">
            <div className="heading-3">High-quality solutions</div>
            <div className="big text-white-lighter text-white-darken">
              We are committed to providing the best possible solutions at a
              competitive cost. Our team is ready to offer a wide range of
              various architectural services.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
