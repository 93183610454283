import React, { Fragment } from "react";
import { ServImg } from "./ServImg";
import Services from "./Services";

export const MainServe = () => {
  return (
    <Fragment>
      <ServImg />
      <Services />
    </Fragment>
  );
};
