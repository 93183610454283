import React from "react";
import blog1 from "../../assets/projects/1.jpg";
import blog2 from "../../assets/projects/2.jpg";
import blog3 from "../../assets/projects/3.jpg";
import blog4 from "../../assets/projects/4.jpg";
import blog5 from "../../assets/04.jpg";
import blog6 from "../../assets/08.jpg";
const BlogItems = () => {
  return (
    <section id="latest-blog" className="scrollspy-section padding-large">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="post-grid">
              <div className="row">
                <div className="col-md-4">
                  <article className="column">
                    <figure>
                      <a href="#" className="image-hvr-effect">
                        <img src={blog3} alt="post" className="post-image" />
                      </a>
                    </figure>

                    <div className="post-item">
                      <div className="meta-date">Mar 30, 2021</div>
                      <h3 className="post-title">
                        <a href="#">
                          modern vibes bedrooms, todays trending design
                        </a>
                      </h3>
                      <p>
                        Lectus molestie id enim ipsum. Netus sed cursus nibh
                        iaculis ipsum turpis nulla blandit dui.
                      </p>
                    </div>
                  </article>
                </div>

                <div className="col-md-4">
                  <article className="column">
                    <figure>
                      <a href="#" className="image-hvr-effect">
                        <img src={blog1} alt="post" className="post-image" />
                      </a>
                    </figure>
                    <div className="post-item">
                      <div className="meta-date">Mar 29, 2021</div>
                      <h3 className="post-title">
                        <a href="#">
                          How to make minimalist living rooms pop-out
                        </a>
                      </h3>
                      <p>
                        Lectus molestie id enim ipsum. Netus sed cursus nibh
                        iaculis ipsum turpis nulla blandit dui.
                      </p>
                    </div>
                  </article>
                </div>

                <div className="col-md-4">
                  <article className="column">
                    <figure>
                      <a href="#" className="image-hvr-effect">
                        <img src={blog2} alt="post" className="post-image" />
                      </a>
                    </figure>
                    <div className="post-item">
                      <div className="meta-date">Mar 27, 2021</div>
                      <h3 className="post-title">
                        <a href="#">
                          how to make your resort that looks outstanding
                        </a>
                      </h3>
                      <p>
                        Lectus molestie id enim ipsum. Netus sed cursus nibh
                        iaculis ipsum turpis nulla blandit dui.
                      </p>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="post-grid">
              <div className="row">
                <div className="col-md-4">
                  <article className="column">
                    <figure>
                      <a href="#" className="image-hvr-effect">
                        <img src={blog6} alt="post" className="post-image" />
                      </a>
                    </figure>

                    <div className="post-item">
                      <div className="meta-date">Mar 30, 2021</div>
                      <h3 className="post-title ">
                        <a href="#">
                          modern vibes bedrooms, todays trending design
                        </a>
                      </h3>
                      <p>
                        Lectus molestie id enim ipsum. Netus sed cursus nibh
                        iaculis ipsum turpis nulla blandit dui.
                      </p>
                    </div>
                  </article>
                </div>

                <div className="col-md-4">
                  <article className="column">
                    <figure>
                      <a href="#" className="image-hvr-effect">
                        <img src={blog4} alt="post" className="post-image" />
                      </a>
                    </figure>
                    <div className="post-item">
                      <div className="meta-date">Mar 29, 2021</div>
                      <h3 className="post-title">
                        <a href="#">
                          How to make minimalist living rooms pop-out
                        </a>
                      </h3>
                      <p>
                        Lectus molestie id enim ipsum. Netus sed cursus nibh
                        iaculis ipsum turpis nulla blandit dui.
                      </p>
                    </div>
                  </article>
                </div>

                <div className="col-md-4">
                  <article className="column">
                    <figure>
                      <a href="#" className="image-hvr-effect">
                        <img src={blog5} alt="post" className="post-image" />
                      </a>
                    </figure>
                    <div className="post-item">
                      <div className="meta-date">Mar 27, 2021</div>
                      <h3 className="post-title">
                        <a href="#">
                          how to make your resort that looks outstanding
                        </a>
                      </h3>
                      <p>
                        Lectus molestie id enim ipsum. Netus sed cursus nibh
                        iaculis ipsum turpis nulla blandit dui.
                      </p>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogItems;
