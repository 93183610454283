import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import img1 from "../../assets/icon/dark/1.png";
import img2 from "../../assets/icon/dark/2.png";
import img3 from "../../assets/icon/dark/3.png";
import img4 from "../../assets/icon/dark/4.png";
import img5 from "../../assets/icon/dark/5.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Clients = () => {
  // const { t } = useTranslation();
  // const { arrivals, loading } = useSelector((state) => state.homeSlicer);

  return (
    <section className="section section-lg bg-gray-100 section-lined text-center">
      <Swiper
        spaceBetween={30}
        pagination={{ clickable: true }}
        modules={[Pagination]}
        slidesPerView={1}
        breakpoints={{
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 1,
          },
        }}
        loop={true}
      >
        <SwiperSlide>
          <article className="quote-minimal">
            <div className="quote-body">
              <q>
                We selected Arty because of their architects’ rigorous design
                background and education. They did the best job!
              </q>
            </div>
            <div className="quote-footer">
              <cite className="quote-minimal-cite">John Smith</cite>,{" "}
              <img src={img1} alt="asd" className="clientImg" />
              <span className="quote-minimal-description">
                “STP productions”
              </span>
            </div>
          </article>
        </SwiperSlide>
        <SwiperSlide>
          <article className="quote-minimal">
            <div className="quote-body">
              <q>
                When we needed design and architecture specialists, your team
                was there for us. Thank you!
              </q>
            </div>
            <div className="quote-footer">
              <cite className="quote-minimal-cite">Kate Williams</cite>,{" "}
              <img src={img2} alt="asd" className="clientImg" />
              <span className="quote-minimal-description">“Fireworx”</span>
            </div>
          </article>
        </SwiperSlide>
        <SwiperSlide>
          <article className="quote-minimal">
            <div className="quote-body">
              <q>
                Out of all architecture companies offering design & construction
                services, you are the best!
              </q>
            </div>
            <div className="quote-footer">
              <cite className="quote-minimal-cite">Peter McMillan</cite>,{" "}
              <img src={img3} alt="asd" className="clientImg" />
              <span className="quote-minimal-description">“MCM ltd.”</span>
            </div>
          </article>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default Clients;
