import React, { Fragment } from "react";
import Banner from "./Banner";
import "swiper/css";
import AboutHome from "./AboutHome";
import WhyUs from "./WhyUs";
import ProjectsGallery from "./ProjectsGallery";
import GeneralFAQ from "./GeneralFAQ";
import News from "./Dream";
import Clients from "./Clients";
import Material from "./Material";
import Contact from "../contact/Contact";
import About from "../about/About";

const Home = () => {
  return (
    <Fragment>
      <Banner />
      <AboutHome />
      <ProjectsGallery />
      <WhyUs />
      <News />
      <Material />
      <GeneralFAQ />
      <Clients />
    </Fragment>
  );
};

export default Home;
