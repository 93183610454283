import { createSlice } from "@reduxjs/toolkit";
import {
  GetOrders,
  setCheckout,
  applyCoupon,
  cancelOrders,
} from "./checkAction";

const initialState = {
  parch: [],
  orders: [],
  coupon: {},
  loading: "pending",
  error: null,
};

const checkslice = createSlice({
  name: "check",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setCheckout.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(setCheckout.fulfilled, (state, action) => {
        state.parch = action.payload;
        state.loading = "idle";
        state.error = null;
      })
      .addCase(GetOrders.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(GetOrders.fulfilled, (state, action) => {
        state.orders = action.payload;
        state.loading = "idle";
        state.error = null;
      })
      .addCase(GetOrders.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      })

      .addCase(setCheckout.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      })
      .addCase(applyCoupon.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(applyCoupon.fulfilled, (state, action) => {
        state.coupon = action.payload;
        state.loading = "idle";
        state.error = null;
      })
      .addCase(applyCoupon.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      });
  },
});

export default checkslice.reducer;
