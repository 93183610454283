import { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { gettotalOffers } from "../../store/totaloffer/totaloffAction";
import { Link } from "react-router-dom";
import { baseUrl } from "../../utils/APi";
import { Swiper, SwiperSlide } from "swiper/react";
import img1 from "../../assets/clients/1.png";
import img2 from "../../assets/clients/2.png";
import img3 from "../../assets/clients/3.png";
import img4 from "../../assets/clients/4.png";
import img5 from "../../assets/clients/5.png";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
const Material = () => {
  // const dispatch = useDispatch();
  // const lan = useSelector((state) => state.LanSlicer.language);
  // const { offers } = useSelector((state) => state.Offers);

  // useEffect(() => {
  //   if (offers?.length === 0 ) {
  //    const promise= dispatch(gettotalOffers(lan));
  //     return () => {
  //       promise.abort();
  //     };
  //   }
  // }, [lan, dispatch,offers?.length]);

  return (
    <Fragment>
      <section className="section section-lg bg-default section-lined">
        <div className="container">
          <div className="row row-40">
            <div className="col-sm-6 col-md-4 offset-left-16 wow fadeInUp">
              <div className="blurb-minimal unit unit-spacing-sm flex-column flex-lg-row">
                <div className="unit-left">
                  <div className="blurb-minimal-icon construction-icon-12"></div>
                </div>
                <div className="unit-body">
                  <h5 className="blurb-minimal-title">Modern Materials</h5>
                  <p className="blurb-minimal-exeption">
                    All our projects are designed and built using the best
                    materials.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-sm-6 col-md-4 wow fadeInUp"
              data-wow-delay="0.2s"
            >
              <div className="blurb-minimal unit unit-spacing-sm flex-column flex-lg-row">
                <div className="unit-left">
                  <div className="blurb-minimal-icon construction-icon-13"></div>
                </div>
                <div className="unit-body">
                  <h5 className="blurb-minimal-title">Creative Ideas</h5>
                  <p className="blurb-minimal-exeption">
                    Creativity is a vital part of the projects we design for
                    you.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-sm-6 col-md-4 wow fadeInUp"
              data-wow-delay="0.4s"
            >
              <div className="blurb-minimal unit unit-spacing-sm flex-column flex-lg-row">
                <div className="unit-left">
                  <div className="blurb-minimal-icon construction-icon-18"></div>
                </div>
                <div className="unit-body">
                  <h5 className="blurb-minimal-title">Unique Approach</h5>
                  <p className="blurb-minimal-exeption">
                    Our approach combines dedication and professionalism.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container ">
          <div className="row row-40  justify-content-between">
            <div className="col-lg-6 col-xxl-5">
              <h3 className="mb-5  text-title">Our Clients</h3>

              <Swiper
                spaceBetween={30}
                pagination={{ clickable: true }}
                modules={[Pagination]}
                slidesPerView={1}
                breakpoints={{
                  768: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 1,
                  },
                }}
                loop={true}
              >
                <SwiperSlide>
                  <article className="quote-minimal">
                    <div className="quote-body">
                      <q>
                        We selected Arty because of their architects’ rigorous
                        design background and education. They did the best job!
                      </q>
                    </div>
                    <div className="quote-footer">
                      <cite className="quote-minimal-cite">John Smith</cite>,{" "}
                      <span className="quote-minimal-description">
                        “STP productions”
                      </span>
                    </div>
                  </article>
                </SwiperSlide>
                <SwiperSlide>
                  <article className="quote-minimal">
                    <div className="quote-body">
                      <q>
                        When we needed design and architecture specialists, your
                        team was there for us. Thank you!
                      </q>
                    </div>
                    <div className="quote-footer">
                      <cite className="quote-minimal-cite">Kate Williams</cite>,{" "}
                      <span className="quote-minimal-description">
                        “Fireworx”
                      </span>
                    </div>
                  </article>
                </SwiperSlide>
                <SwiperSlide>
                  <article className="quote-minimal">
                    <div className="quote-body">
                      <q>
                        Out of all architecture companies offering design &
                        construction services, you are the best!
                      </q>
                    </div>
                    <div className="quote-footer">
                      <cite className="quote-minimal-cite">Peter McMillan</cite>
                      ,{" "}
                      <span className="quote-minimal-description">
                        “MCM ltd.”
                      </span>
                    </div>
                  </article>
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="col-lg-6">
              <div className="box-border-wrap box-border-wrap-left">
                <div className="row">
                  <a className="col-6 col-md-4 box-border" href="#">
                    <img src={img1} alt="" width="120" height="130" />
                  </a>
                  <a className="col-6 col-md-4 box-border" href="#">
                    <img src={img2} alt="" width="120" height="130" />
                  </a>
                  <a className="col-6 col-md-4 box-border" href="#">
                    <img src={img3} alt="" width="120" height="130" />
                  </a>
                  <a className="col-6 col-md-4 box-border" href="#">
                    <img src={img4} alt="" width="120" height="130" />
                  </a>
                  <a className="col-6 col-md-4 box-border" href="#">
                    <img src={img5} alt="" width="120" height="130" />
                  </a>
                  <a className="col-6 col-md-4 box-border" href="#">
                    <div className="icon icon-sm mdi mdi-arrow-right"></div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Material;
