// import { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
// import { baseUrl } from "../../utils/APi";
// import { getSlides } from "../../store/slider/SliderAction";
// import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import bg from "../../assets/05.jpg";
import bg1 from "../../assets/05.jpg";
import bg2 from "../../assets/04.jpg";
import bg3 from "../../assets/08.jpg";
import "swiper/css/navigation";
// import { Pagination } from "swiper/modules";
// import { useTranslation } from "react-i18next";
// import Loader from "../../component/Loader";

const HomeSlider = () => {
  // const dispatch = useDispatch();
  // const { slider, loading } = useSelector((state) => state.slider);
  // const { t } = useTranslation();

  // useEffect(() => {
  //   if (slider?.length === 0) {
  //     const promise = dispatch(getSlides());
  //     return () => {
  //       promise.abort();
  //     };
  //   }
  // }, [dispatch, slider?.length]);

  // if (loading !== "idle") return <Loader />;

  return (
    
    <section className="section context-dark section-jumbotron bg-cover" style={{background: `url(${bg}) #151515` ,backgroundPosition:"center" ,objectFit:"cover"}}>
    <div className="panel-left d-flex"><Link className="brand" to={"/"}><img className="brand-logo-dark" src="images/logo-default-465x134.png" alt="" width="232" height="67"/><img className="brand-logo-light" src="images/logo-inverse-86x104.png" alt="" width="86" height="104"/></Link>
      <ul className="list-md custom-list">
        <li><i className="fa-brands fa-behance  icon icon-circle icon-sm icon-gray-filled"></i></li>
        <li><i className="fa-brands fa-facebook icon icon-circle icon-sm icon-gray-filled"></i></li>
        <li><i className="fa-brands fa-instagram icon icon-circle icon-sm icon-gray-filled"></i></li>
      </ul>
    </div>
    <div className="section-fullheight">
      <div className="section-fullheight-inner section-md text-center text-lg-left">
        <div className="container">
          <div className="row justify-content-center justify-content-lg-start">
            <div className="col-md-10 offset-lg-1 col-lg-8 offset-xl-1 col-xl-9 offset-xxl-2 col-xxl-7">
              <div className="jumbotron-custom-1">
                <div className="text-1 font-weight-medium">Welcome to Arty</div>
                <div className="title text-decorative">Top-Notch</div>
                <div className="title">Architecture</div>
                <p className="big-2 text-white-darken">With 19+ years’ experience in combining traditions and innovations, Arty creates settings to inspire the way people live, learn, heal, work and play.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ul className="project-list">
      <li className="bg-image" style={{background: `url(${bg1}) #151515`}}><a href="#">Architecture</a></li>
      <li className="bg-image" style={{background: `url(${bg3}) #151515`}}><a href="#">Design</a></li>
      <li className="bg-image" style={{background: `url(${bg2}) #151515`}}><a href="#">3d modeling</a></li>
    </ul>
  </section>
 
);
};

export default HomeSlider;
