import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store/store.js";
import Loader from "./component/Loader.jsx";
import { AuthGuard } from "./component/AuthGuard.jsx";
import {
  createRoutesFromElements,
  createBrowserRouter,
  Route,
  RouterProvider,
} from "react-router-dom";
// import Jobs from "./pages/jobs/Jobs.jsx";
// import { Toast } from "./component/toast/Toast.jsx";
import Home from "./pages/home/Home";
// import SingleProduct from "./pages/Single-product/SingleProduct";
// import MainShop from "./pages/shop/MainShop.jsx";
import MainLayout from "./layouts/mainLayout/MainLayout.jsx";
// import Category from "./pages/shop/Category.jsx";
import About from "./pages/about/About";
// import Policy from "./pages/about/Policy";
// import Shop from "./pages/shop/Shop";
// import Login from "./pages/profile/Login";
// import Register from "./pages/profile/Register";
// import Contact from "./pages/contact/Contact";
// import Cart from "./pages/cart/Cart";
// import Wishlist from "./pages/wishlist/Wishlist";
// import Branch from "./pages/branch/Branch";
// import Profile from "./pages/profile/Profile";
// import Offer from "./pages/home/Offer";
// import TotalOffers from "./pages/offers/TotalOffers";
// import JobForm from "./pages/jobs/JobForm.jsx";
import "./all.min.css";
import Services from "./pages/services/Services";
import Projects from "./pages/projects/Projects.jsx";
import ProjectDetails from "./pages/projects/ProjectDetails.jsx";
import Blog from "./pages/blog/Blog.jsx";
import Contact from "./pages/contact/Contact.jsx";
import { MainServe } from "./pages/services/MainServe.jsx";

const routes = createRoutesFromElements(
  <Route path="/" element={<MainLayout />}>
    <Route index element={<Home />} />
    <Route path="about" element={<About />} />
    <Route path="services" element={<MainServe />} />
    <Route path="projects" element={<Projects />} />
    <Route path="projectDetail" element={<ProjectDetails />} />
    <Route path="blog" element={<Blog />} />
    <Route path="contact" element={<Contact />} />
  </Route>
);

const router = createBrowserRouter(routes);

const App = () => {
  // const [isRehydrated, setIsRehydrated] = useState(false);

  // useEffect(() => {
  //   persistor.persist();
  //   setIsRehydrated(true);
  // }, []);
  // console.log("app")
  return <RouterProvider router={router} />;
  //
  //   <PersistGate loading={<Loader />} persistor={persistor}>
  //     {isRehydrated && (
  //        <I18nextProvider i18n={i18n}>
  //        <Toast />

  //        </RouterProvider>
  //       </I18nextProvider>
  //     )}
  //   </PersistGate>
  //
};

export default App;
