import { Fragment } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import bg from "../../assets/08.jpg";
import blog1 from "../../assets/projects/1.jpg";
import blog2 from "../../assets/projects/2.jpg";
import blog3 from "../../assets/projects/3.jpg";
import BlogItems from "./BlogItems";

const Blog = () => {
  return (
    <Fragment>
      <section
        className="breadcrumbs-custom bg-image context-dark"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="container">
          <h3 className="breadcrumbs-custom-title">Our Blog</h3>
          <pre-footer-classic className="breadcrumbs-custom-subtitle">
            modern vibes todays trending design
          </pre-footer-classic>
        </div>
      </section>

      <section className="section context-dark section-relative my-4">
        <div className="container">
          <div className="row">
            <div className="col-md-5 text-block-1 wow fadeInLeft">
              <div className="section-sm section-sm-3">
                <h3 className="text-title">Eastwood Hotel</h3>
                <p className="big">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud
                </p>
                <div className="button-wrap">
                  <a
                    className="button button-default button-invariable"
                    href="#"
                  >
                    view more
                  </a>
                </div>
                <ul className="list-inline list-inline-sm-1">
                  <li>
                    <p>Share</p>
                  </li>
                  <li>
                    <a
                      className="icon icon-sm link-default mdi mdi-facebook"
                      href="#"
                    ></a>
                  </li>
                  <li>
                    <a
                      className="icon icon-sm link-default mdi mdi-instagram"
                      href="#"
                    ></a>
                  </li>
                  <li>
                    <a
                      className="icon icon-sm link-default mdi mdi-twitter"
                      href="#"
                    ></a>
                  </li>
                  <li>
                    <a
                      className="icon icon-sm link-default mdi mdi-youtube-play"
                      href="#"
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="swiper-section">
          <div className="swiper-container swiper-slider swiper-slider-1">
            <Swiper
              spaceBetween={30}
              pagination={{ clickable: true }}
              navigation={{
                nextEl: ".custom-swiper-button-next",
                prevEl: ".custom-swiper-button-prev",
              }}
              modules={[Navigation]}
              slidesPerView={1}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 1,
                },
              }}
              loop={true}
            >
              <SwiperSlide>
                <img src={blog1} alt="blog" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={blog2} alt="blog" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={blog3} alt="blog" />
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="swiper-pagination"></div>
          <div className="custom-swiper-button-wrap">
            <div className="custom-swiper-button-next mdi mdi-arrow-right"></div>
            <div className="custom-swiper-button-prev mdi mdi-arrow-left"></div>
          </div>
        </div>
      </section>
      <BlogItems />
    </Fragment>
  );
};

export default Blog;
