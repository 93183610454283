import React from "react";
import bg from "../../assets/0.jpg";

export const BreadImg = () => {
  return (
    <section
      className="breadcrumbs-custom bg-image context-dark"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="container">
        <h3 className="breadcrumbs-custom-title">About Us</h3>
        <pre-footer-classic className="breadcrumbs-custom-subtitle">
          A few words about our design studio
        </pre-footer-classic>
      </div>
    </section>
  );
};
