import { useRef, useEffect } from "react";
import mixitup from "mixitup";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import Fancybox from "../../component/FancyBox";

import img1 from "../../assets/postimg1.jpg";
import img2 from "../../assets/postimg2.jpg";
import img3 from "../../assets/postimg3.jpg";
import img4 from "../../assets/singleimage.jpg";
import img5 from "../../assets/tab1.jpg";
import img6 from "../../assets/tab2.jpg";
import icon from "../../assets/icon/dark/6.png";

const ProjectsGallery = () => {
  const galleryRef = useRef(null);

  useEffect(() => {
    if (galleryRef.current) {
      mixitup(galleryRef.current, {
        selectors: {
          target: ".mix",
        },
        animation: {
          duration: 250,
        },
      });
    }
  }, [galleryRef]);

  const items = [
    {
      title: "Eastwood Hotel",
      image: img1,
      description:
        "We deliver reliable architectural solutions to our individual and corporate clients throughout the USA.",
      delay: "0s",
      link: "#",
    },
    {
      title: "Southward Concert Hall",
      image: img2,
      description:
        "We deliver reliable architectural solutions to our individual and corporate clients throughout the USA.",
      delay: "0.2s",
      link: "#",
    },
    {
      title: "California Mega Mall",
      image: img3,
      description:
        "We deliver reliable architectural solutions to our individual and corporate clients throughout the USA.",
      delay: "0.4s",
      link: "#",
    },
    {
      title: "Hearst Business Center",
      image: img4,
      description:
        "We deliver reliable architectural solutions to our individual and corporate clients throughout the USA.",
      delay: "0.2s",
      link: "#",
    },
    {
      title: "Westwood Child Center",
      image: img5,
      description:
        "We deliver reliable architectural solutions to our individual and corporate clients throughout the USA.",
      delay: "0.4s",
      link: "#",
    },
    {
      title: "Modern Art Gallery",
      image: img6,
      description:
        "We deliver reliable architectural solutions to our individual and corporate clients throughout the USA.",
      delay: "0.6s",
      link: "#",
    },
  ];

  return (
    <section className="section section-lg bg-default section-lined ">
      <div className="container container-custom-width">
        <h3 className="text-center custom-title text-title">
          Our Latest Projects
        </h3>
        <div className="row row-custom-width row-30 row-xxl-100 row-flex">
          {items.map((item, idx) => (
            <div className="col-sm-6 col-lg-4 wow fadeInRight" key={idx}>
              <Fancybox delegate="[data-fancybox]" options={{}}>
                <div
                  style={{ backgroundImage: `url(${item.image})` }}
                  alt="Project Imasge"
                  className="project-grid"
                >
                  <div className="inner">
                    <img src={icon} alt="" />
                    <h5 className="title text-capitalize font-weight-medium">
                      <a href="#">{item.title}</a>
                    </h5>
                    <p className="font-weight-regular exeption">
                      {item.description}
                    </p>
                    <a
                      data-fancybox="gallery"
                      href={item.image}
                      className="button button-default"
                    >
                      View
                    </a>
                  </div>
                </div>
              </Fancybox>
            </div>
          ))}
        </div>
        <div className="button-wrap-1 text-center">
          <a className="button button-default" href="#">
            View All Projects
          </a>
        </div>
      </div>
    </section>
  );
};

export default ProjectsGallery;
