import { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Loader from "./../../component/Loader";
import {
  getContactInfo,
  formContact,
  getSocialMedia,
} from "../../store/contact/contactAction";
import { sucess, failed } from "../../component/toast/Toast";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import bgConatct from "../../assets/header-bg-2.jpg";

const Contact = () => {
  // const { t } = useTranslation();
  // const dispatch = useDispatch();
  // const lan = useSelector((state) => state.LanSlicer.language);

  // const formik = useFormik({
  //   initialValues: {
  //     name: "",
  //     email: "",
  //     phone: "",
  //     message: "",
  //   },
  //   validateOnChange: true,
  //   onSubmit: (values) => {
  //     dispatch(formContact(values)).then((result) => {
  //       if (!result.error) {
  //         sucess(t("Congrats ... we will contact you soon"));
  //       } else {
  //         failed(t("please try again later ... "));
  //       }
  //     });
  //   },
  // });

  // const { info, SocialMedia, loading } = useSelector(
  //   (state) => state.contactSlice
  // );

  // const stripHtmlTags = (html) => {
  //   const doc = new DOMParser().parseFromString(html, "text/html");
  //   return doc.body.textContent || "";
  // };
  // const mapinfo = stripHtmlTags(
  //   info
  //     ?.map((item) => {
  //       if (item?.name === "الخريطة") {
  //         return item?.value;
  //       }
  //       return null;
  //     })
  //     .join("")
  // );

  // useEffect(() => {
  //   dispatch(getContactInfo(lan));
  //   dispatch(getSocialMedia(lan));
  // }, [lan, dispatch]);

  return (
    <Fragment>
      <section
        class="breadcrumbs-custom bg-image context-dark"
        style={{ backgroundImage: `url(${bgConatct})` }}
      >
        <div class="container">
          <h3 class="breadcrumbs-custom-title">Contacts</h3>
          <pre-footer-classic class="breadcrumbs-custom-subtitle">
            View our contacts or write us a message
          </pre-footer-classic>
        </div>
      </section>

      <section class="section section-md bg-default">
        <div class="container">
          <div class="row row-40">
            <div class="col-sm-6 col-md-4">
              <div class="box-lined">
                <div class="box-lined-body">
                  <h6 class="box-lined-title">Email & telephone</h6>
                  <ul class="box-lined-list text-black">
                    <li>
                      <a class="big link-default" href="mailto:#">
                        info@demolink.org
                      </a>
                    </li>
                    <li>
                      <a class="big link-default" href="tel:#">
                        1-300-123-1234
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-4">
              <div class="box-lined">
                <div class="box-lined-body">
                  <h6 class="box-lined-title">Address</h6>
                  <ul class="box-lined-list text-black">
                    <li class="big">
                      1418 Riverwood Drive, Suite 3845 Cottonwood, CA 96022
                      United States
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-4">
              <div class="box-lined">
                <div class="box-lined-body">
                  <h6 class="box-lined-title">Socials</h6>
                  <ul class="list-inline list-inline-sm">
                    <li>
                      <a
                        class="icon icon-sm icon-bordered link-default mdi mdi-facebook"
                        href="#"
                      ></a>
                    </li>
                    <li>
                      <a
                        class="icon icon-sm icon-bordered link-default mdi mdi-instagram"
                        href="#"
                      ></a>
                    </li>
                    <li>
                      <a
                        class="icon icon-sm icon-bordered link-default mdi mdi-behance"
                        href="#"
                      ></a>
                    </li>
                    <li>
                      <a
                        class="icon icon-sm icon-bordered link-default mdi mdi-twitter"
                        href="#"
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section section-md bg-default">
        <div class="container">
          <h4 className="text-title">Get in touch with us</h4>
          <p class="big">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud
          </p>

          <form
            class="rd-form rd-mailform form-boxed"
            data-form-output="form-output-global"
            data-form-type="contact"
            method="post"
            action="bat/rd-mailform.php"
          >
            <div class="row row-50">
              <div class="col-lg-4">
                <div class="form-wrap form-wrap-icon">
                  <div class="form-icon mdi mdi-account-outline"></div>
                  <input
                    class="form-input"
                    id="contact-name"
                    type="text"
                    name="name"
                    data-constraints="@Required"
                  />
                  <label class="form-label" for="contact-name">
                    Name
                  </label>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-wrap form-wrap-icon">
                  <div class="form-icon mdi mdi-email-outline"></div>
                  <input
                    class="form-input"
                    id="contact-email"
                    type="email"
                    name="email"
                    data-constraints="@Email @Required"
                  />
                  <label class="form-label" for="contact-email">
                    E-mail
                  </label>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-wrap form-wrap-icon">
                  <div class="form-icon mdi mdi-phone"></div>
                  <input
                    class="form-input"
                    id="contact-phone"
                    type="text"
                    name="phone"
                    data-constraints="@Numeric"
                  />
                  <label class="form-label" for="contact-phone">
                    Phone
                  </label>
                </div>
              </div>
              <div class="col-12">
                <div class="form-wrap form-wrap-icon">
                  <div class="form-icon mdi mdi-message-outline"></div>
                  <label class="form-label" for="contact-message">
                    Message
                  </label>
                  <textarea
                    class="form-input"
                    id="contact-message"
                    name="message"
                    data-constraints="@Required"
                  ></textarea>
                </div>
              </div>
              <div class="col-md-12">
                <button class="button button-default" type="submit">
                  Send
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </Fragment>
  );
};

export default Contact;
