import { Fragment, useEffect } from "react";

// import { useTranslation } from "react-i18next";
// import { useDispatch, useSelector } from "react-redux";
// import { getPolicy } from "../../store/about/aboutAction";
import img2 from "../../assets/5.jpg";
import img1 from "../../assets/tab2.jpg";
import img3 from "../../assets/tab3.jpg";
import mainbg from "../../assets/1.jpg";
import img4 from "../../assets/review-bg.jpg";
import Loader from "../../component/Loader";
import { Link } from "react-router-dom";
const WhoWE = () => {
  // const { t } = useTranslation();
  // const dispatch = useDispatch();
  // const lan = useSelector((state) => state.LanSlicer.language);
  // const { policy, loading } = useSelector((state) => state.aboutSlicer);

  // useEffect(() => {
  //   dispatch(getPolicy(lan));
  // }, [dispatch, lan]);

  return (
    <Fragment>
      <section className="section section-lg bg-default">
        <div className="container">
          <div className="row row-40 justify-content-between">
            <div className="col-lg-7">
              <h3 className="custom-title text-title">Who We Are</h3>
              <p className="big offset-top">
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat.
              </p>
              <p className="big">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nis
              </p>
              <div className="row row-40 align-items-center offset-custom-2">
                <div className="col-md-6">
                  <article className="box-counter box-counter-2">
                    <div className="box-counter-main">
                      <div className="counter">15</div>
                      <div className="icon linearicons-trophy2"></div>
                    </div>
                    <div className="box-counter-title">
                      International awards
                    </div>
                  </article>
                </div>
                <div className="col-md-6">
                  <ul className="list-marked-2">
                    <li>
                      <span className="big font-weight-light">
                        6 Architecture Awards
                      </span>
                    </li>
                    <li>
                      <span className="big font-weight-light">
                        5 Best Projects
                      </span>
                    </li>
                    <li>
                      <span className="big font-weight-light">
                        4 International Awards
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <p className="big">
                Elit at imperdiet dui accumsan sit. Arcu cursus vitae congue
                mauris rhoncus aenean. Consectetur adipiscing elit duis
                tristique sollicitudin nibh sit. Magna ac placerat vestibulum
                lectus.
              </p>
              <div className="img-wrap-1">
                <img src={mainbg} alt="" width="650" height="325" />
              </div>
              <div className="row row-30 row-xxl-55">
                <div className="col-md-6 offset-left-16">
                  <div className="blurb-minimal unit unit-spacing-sm flex-column flex-lg-row">
                    <div className="unit-left">
                      <div className="blurb-minimal-icon construction-icon-13"></div>
                    </div>
                    <div className="unit-body">
                      <h5 className="blurb-minimal-title">Creative Ideas</h5>
                      <p className="blurb-minimal-exeption">
                        Suspendisse ultrices gravida dictum fusce ut placerat
                        adipiscing elit duis .
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="blurb-minimal unit unit-spacing-sm flex-column flex-lg-row">
                    <div className="unit-left">
                      <div className="blurb-minimal-icon construction-icon-18"></div>
                    </div>
                    <div className="unit-body">
                      <h5 className="blurb-minimal-title">Unique Approach</h5>
                      <p className="blurb-minimal-exeption">
                        Congue nisi vitae suscipit tellus mauris sollicitudin
                        nibh sit
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 offset-left-16">
                  <div className="blurb-minimal unit unit-spacing-sm flex-column flex-lg-row">
                    <div className="unit-left">
                      <div className="blurb-minimal-icon construction-icon-12"></div>
                    </div>
                    <div className="unit-body">
                      <h5 className="blurb-minimal-title">Modern Materials</h5>
                      <p className="blurb-minimal-exeption">
                        Non curabitur gravida arcu ac. Lobortis elementum nibh
                        tellus.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="blurb-minimal unit unit-spacing-sm flex-column flex-lg-row">
                    <div className="unit-left">
                      <div className="blurb-minimal-icon construction-icon-07"></div>
                    </div>
                    <div className="unit-body">
                      <h5 className="blurb-minimal-title">Best Solutions</h5>
                      <p className="blurb-minimal-exeption">
                        Sapien nec sagittis aliquam malesuada bibendum ac
                        placerat vestibulum lectus.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="row no-gutters">
                <div className="col-sm-6 col-lg-12">
                  <div className="box-team">
                    <img src={img2} alt="" width="390" height="332" />
                    <div className="meta">
                      <div className="heading-6 title">Nathan Porter</div>
                      <p className="position">Founder, Director</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-12">
                  <div className="box-team">
                    <img src={img1} alt="" width="390" height="332" />
                    <div className="meta">
                      <div className="heading-6 title">Sarah Parker</div>
                      <p className="position">Exterior Designer</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-12">
                  <div className="box-team">
                    <img src={img3} alt="" width="390" height="332" />
                    <div className="meta">
                      <div className="heading-6 title">Sam Lee</div>
                      <p className="position">Interior Designer</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-12">
                  <div className="box-team">
                    <img src={img4} alt="" width="390" height="332" />
                    <div className="meta">
                      <div className="heading-6 title">Jane Smith</div>
                      <p className="position">Senior Architect</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section section-md bg-default">
        <div className="container container container-custom-width-1">
          <div className="row row-30 row-xxl-60 row-flex row-custom">
            <div className="col-sm-6 col-md-4">
              <div className="blurb-boxed">
                <div className="blurb-boxed-header">
                  <h5 className="blurb-boxed-title">3D Modeling</h5>
                  <div className="blurb-boxed-icon linearicons-cube"></div>
                </div>
                <ul className="blurb-boxed-list">
                  <li>
                    <a href="#">Interior Rendering</a>
                  </li>
                  <li>
                    <a href="#">Visualization</a>
                  </li>
                  <li>
                    <a href="#">VR Architecture</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="blurb-boxed">
                <div className="blurb-boxed-header">
                  <h5 className="blurb-boxed-title">Architecture</h5>
                  <div className="blurb-boxed-icon linearicons-pencil-ruler"></div>
                </div>
                <ul className="blurb-boxed-list">
                  <li>
                    <a href="#">Planning & Development</a>
                  </li>
                  <li>
                    <a href="#">Project Management</a>
                  </li>
                  <li>
                    <a href="#">Structural Engineering</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="blurb-boxed">
                <div className="blurb-boxed-header">
                  <h5 className="blurb-boxed-title">Design</h5>
                  <div className="blurb-boxed-icon linearicons-lamp"></div>
                </div>
                <ul className="blurb-boxed-list">
                  <li>
                    <a href="#">Interior Design</a>
                  </li>
                  <li>
                    <a href="#">Architectural Design</a>
                  </li>
                  <li>
                    <a href="#">Technical Detailing</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="blurb-boxed">
                <div className="blurb-boxed-header">
                  <h5 className="blurb-boxed-title">Surveying</h5>
                  <div className="blurb-boxed-icon linearicons-lifebuoy"></div>
                </div>
                <ul className="blurb-boxed-list">
                  <li>
                    <a href="#">Land Surveying</a>
                  </li>
                  <li>
                    <a href="#">Leveling</a>
                  </li>
                  <li>
                    <a href="#">Structural Surveying</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="blurb-boxed">
                <div className="blurb-boxed-header">
                  <h5 className="blurb-boxed-title">Administration</h5>
                  <div className="blurb-boxed-icon linearicons-copy"></div>
                </div>
                <ul className="blurb-boxed-list">
                  <li>
                    <a href="#">Accounting</a>
                  </li>
                  <li>
                    <a href="#">Project Management</a>
                  </li>
                  <li>
                    <a href="#">Risk Management</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="blurb-boxed">
                <div className="blurb-boxed-header">
                  <h5 className="blurb-boxed-title">Consulting</h5>
                  <div className="blurb-boxed-icon linearicons-bubble-text"></div>
                </div>
                <ul className="blurb-boxed-list">
                  <li>
                    <a href="#">Building Code Compliance</a>
                  </li>
                  <li>
                    <a href="#">Specification Editing</a>
                  </li>
                  <li>
                    <a href="#">Design Assistance</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default WhoWE;
