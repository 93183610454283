import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAboutSite, getAboutPhoto } from "../../store/about/aboutAction";
import { baseUrl } from "../../utils/APi";
import Loader from "../../component/Loader";
import { Link } from "react-router-dom";
import bgAbout from "../../assets/images/resource/project-detail.jpg";
import bgAboutbottom from "../../assets/images/background/2.jpg";
import img1 from "../../assets/images/background/8.jpg";
import img2 from "../../assets/images/background/1.jpg";
import WhoWE from "./WhoWE";
import { BreadImg } from "./BreadImg";
import BlockQuot from "./BlockQuot";

const About = () => {
  // const { t } = useTranslation();
  // const dispatch = useDispatch();
  // const lan = useSelector((state) => state.LanSlicer.language);
  // const { aboutSite, aboutPhoto, loading } = useSelector(
  //   (state) => state.aboutSlicer
  // );
  // const stripHtmlTags = (html) => {
  //   const doc = new DOMParser().parseFromString(html, "text/html");
  //   return doc.body.textContent || "";
  // };
  // const aboutSiteText = stripHtmlTags(aboutSite ? aboutSite :"");
  // useEffect(() => {
  //   dispatch(getAboutSite(lan));
  //   dispatch(getAboutPhoto());
  // }, [dispatch, lan]);

  return (
    <Fragment>
      <BreadImg />
      <WhoWE />
      {/* <BlockQuot />  */}
    </Fragment>
  );
};

export default About;
