import { Outlet, useLocation } from "react-router-dom";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { Fragment, useEffect } from "react";

import BackTop from "../../component/BackTop";
import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/pagination";
const MainLayout = () => {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, [pathname]);

    return null;
  };

  return (
    <div className="page animated">
      <Header />

      {/* <ScrollToTop /> */}

      <Outlet />

      {/* <QuickModal />
      <CartModal />*/}
      <BackTop />
      <Footer />
    </div>
  );
};

export default MainLayout;
