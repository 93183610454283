import { useRef, useEffect, Fragment } from "react";
import mixitup from "mixitup";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import Fancybox from "../../component/FancyBox";
import bgproject from "../../assets/main-banner.jpg";
import img1 from "../../assets/projects/1.jpg";
import img2 from "../../assets/projects/2.jpg";
import img3 from "../../assets/projects/3.jpg";
import img4 from "../../assets/projects/4.jpg";
import img5 from "../../assets/0.jpg";
import img6 from "../../assets/04.jpg";
import img7 from "../../assets/07.jpg";
import img8 from "../../assets/06.jpg";
import icon from "../../assets/icon/dark/6.png";

const Projects = () => {
  const galleryRef = useRef(null);

  useEffect(() => {
    if (galleryRef.current) {
      mixitup(galleryRef.current, {
        selectors: {
          target: ".mix",
        },
        animation: {
          duration: 250,
        },
      });
    }
  }, [galleryRef]);

  const items = [
    {
      title: "Eastwood Hotel",
      image: img1,
      description:
        "We deliver reliable architectural solutions to our individual and corporate clients throughout the USA.",
      category: "material chemical",
      link: "#",
    },
    {
      title: "Southward Concert Hall",
      image: img2,
      description:
        "We deliver reliable architectural solutions to our individual and corporate clients throughout the USA.",
      category: "power-energy material chemical",
      link: "#",
    },
    {
      title: "California Mega Mall",
      image: img3,
      description:
        "We deliver reliable architectural solutions to our individual and corporate clients throughout the USA.",
      category: "chemical oil-and-gas",
      link: "#",
    },
    {
      title: "Hearst Business Center",
      image: img4,
      description:
        "We deliver reliable architectural solutions to our individual and corporate clients throughout the USA.",
      category: "power-energy oil-and-gas material",
      link: "#",
    },
    {
      title: "Westwood Child Center",
      image: img5,
      description:
        "We deliver reliable architectural solutions to our individual and corporate clients throughout the USA.",
      category: "power-energy oil-and-gas chemical",
      link: "#",
    },
    {
      title: "Modern Art Gallery",
      image: img6,
      description:
        "We deliver reliable architectural solutions to our individual and corporate clients throughout the USA.",
      category: "material power-energy",
      link: "#",
    },
    {
      title: "Eastwood Hotel",
      image: img7,
      description:
        "We deliver reliable architectural solutions to our individual and corporate clients throughout the USA.",
      category: "material chemical",
      link: "#",
    },
    {
      title: "Westwood Child Center",
      image: img8,
      description:
        "We deliver reliable architectural solutions to our individual and corporate clients throughout the USA.",
      category: "power-energy oil-and-gas chemical",
      link: "#",
    },
  ];

  return (
    <Fragment>
      <section
        className="breadcrumbs-custom bg-image context-dark"
        style={{ backgroundImage: `url(${bgproject})` }}
      >
        <div className="container">
          <h3 className="breadcrumbs-custom-title">Our Services</h3>
          <pre-footer-classic className="breadcrumbs-custom-subtitle">
            Our Services that we introduce to our customers
          </pre-footer-classic>
        </div>
      </section>

      <section className="project-secstion-two">
        <div className="container-fluid">
          <div className="sec-title  text-center my-5">
            <span className="float-text text-dark my-5">Gallery</span>
            <h2>'</h2>
          </div>

          <div className="mixitup-gallery pt-4" ref={galleryRef}>
            <div className="filters clearfix text-center mb-4">
              <ul className="filter-tabs filter-btns clearfix">
                <li className="active filter" data-filter="all">
                  All
                </li>
                <li className="filter" data-filter=".chemical">
                  Villa
                </li>
                <li className="filter" data-filter=".material">
                  Real state
                </li>
                <li className="filter" data-filter=".oil-and-gas">
                  Corporate
                </li>
                <li className="filter" data-filter=".power-energy">
                  Retail
                </li>
              </ul>
            </div>

            <div className="row row-custom-width row-30 row-xxl-100 row-flex my-4">
              {items.slice(0, 3).map((item, idx) => (
                <div
                  className={`mix ${item.category} col-sm-6 col-lg-4 wow fadeInRight`}
                  key={idx}
                >
                  <Fancybox delegate="[data-fancybox]" options={{}}>
                    <div
                      style={{ backgroundImage: `url(${item.image})` }}
                      alt="Project Image"
                      className="project-grid"
                    >
                      <div className="inner">
                        <img src={icon} alt="" />
                        <h5 className="title text-capitalize font-weight-medium">
                          <Link to={"/projectDetail"}>{item.title}</Link>
                        </h5>
                        <p className="font-weight-regular exeption">
                          {item.description}
                        </p>
                        <a
                          data-fancybox="gallery"
                          href={item.image}
                          className="button button-default"
                        >
                          View
                        </a>
                      </div>
                    </div>
                  </Fancybox>
                </div>
              ))}
              {items.slice(3, 5).map((item, idx) => (
                <div
                  className={`mix ${item.category} col-sm-6 col-lg-6 wow fadeInRight`}
                  key={idx}
                >
                  <Fancybox delegate="[data-fancybox]" options={{}}>
                    <div
                      style={{ backgroundImage: `url(${item.image})` }}
                      alt="Project Image"
                      className="project-grid"
                    >
                      <div className="inner">
                        <img src={icon} alt="" />
                        <h5 className="title text-capitalize font-weight-medium">
                          <Link to={"/projectDetail"}>{item.title}</Link>
                        </h5>
                        <p className="font-weight-regular exeption">
                          {item.description}
                        </p>
                        <a
                          data-fancybox="gallery"
                          href={item.image}
                          className="button button-default"
                        >
                          View
                        </a>
                      </div>
                    </div>
                  </Fancybox>
                </div>
              ))}
              {items.slice(5, 8).map((item, idx) => (
                <div
                  className={`mix ${item.category} col-sm-6 col-lg-4 wow fadeInRight`}
                  key={idx}
                >
                  <Fancybox delegate="[data-fancybox]" options={{}}>
                    <div
                      style={{ backgroundImage: `url(${item.image})` }}
                      alt="Project Image"
                      className="project-grid"
                    >
                      <div className="inner">
                        <img src={icon} alt="" />
                        <h5 className="title text-capitalize font-weight-medium">
                          <Link to={"/projectDetail"}>{item.title}</Link>
                        </h5>
                        <p className="font-weight-regular exeption">
                          {item.description}
                        </p>
                        <a
                          data-fancybox="gallery"
                          href={item.image}
                          className="button button-default"
                        >
                          View
                        </a>
                      </div>
                    </div>
                  </Fancybox>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Projects;
