import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getarrivals } from "../../store/home/homeAction";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import Loader from "../../component/Loader";
import img1 from "../../assets/0.jpg";
import img2 from "../../assets/05.jpg";
import img3 from "../../assets/04.jpg";
import img4 from "../../assets/09.jpg";

const GeneralFAQ = () => {
  // const dispatch = useDispatch();
  // const { t } = useTranslation();
  // const lan = useSelector((state) => state.LanSlicer.language);
  // const { arrivals, loading } = useSelector((state) => state.homeSlicer);

  // useEffect(() => {
  //  if(arrivals?.length === 0){
  //   const promise = dispatch(getarrivals(lan));
  //     return ()=>{
  //       promise.abort();
  //     }}
  // }, [lan, dispatch,arrivals?.length]);

  // if (loading !== "idle") return <Loader />;
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="section section-lg bg-gray-100 section-lined">
      <div className="container-fluid custom-px ">
        <div className="row row-40 justify-content-between">
          <div className="col-lg-7 col-xl-8">
            <div className="row no-gutters">
              <div className="col-sm-6 wow fadeInLeft">
                <div className="box-team box-team-right">
                  <img src={img1} alt="" />
                  <div className="meta">
                    <div className="heading-6 title">Jane Smith</div>
                    <p className="position">Senior Architect</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 wow fadeInRight">
                <div className="box-team box-team-right">
                  <img src={img2} alt="" />
                  <div className="meta">
                    <div className="heading-6 title">Nathan Porter</div>
                    <p className="position">Founder, Director</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 wow fadeInLeft" data-wow-delay="0.2s">
                <div className="box-team box-team-right">
                  <img src={img3} alt="" />
                  <div className="meta">
                    <div className="heading-6 title">Sam Lee</div>
                    <p className="position">Interior Designer</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 wow fadeInRight" data-wow-delay="0.2s">
                <div className="box-team box-team-right">
                  <img src={img4} alt="" />
                  <div className="meta">
                    <div className="heading-6 title">Sarah Parker</div>
                    <p className="position">Exterior Designer</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-xl-4 px-4">
            <div className="row row-30 row-xxl-55 justify-content-start">
              <div className="col-sm-6 col-lg-9 col-xl-10 ">
                <h3 className="text-title">
                  Meet the Team of Our Designers and Architects
                </h3>
                <div className="big-text">
                  The team of Arty has more than 20 years of experience in
                  architecture.
                </div>
                <div className="divider divider-2 d-none d-lg-block"></div>
              </div>
              <div className="col-sm-6 col-lg-8 col-xl-7">
                <div className="counter-minimal">
                  <div className="counter-left">
                    <div className="counter">20</div>
                  </div>
                  <div className="counter-right">
                    <div className="postfix">+</div>
                    <div className="title">People in team</div>
                  </div>
                </div>
                <ul className="list-marked list-marked-big">
                  <li>
                    28 exceptional
                    <br /> full-time professionals
                  </li>
                  <li>5-year specialized team member practice</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GeneralFAQ;
