import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import bgproject from "../../assets/pro/pro1.jpg";
import pro2 from "../../assets/pro/pro2.jpg";
import pro3 from "../../assets/pro/pro3.jpg";
import pro4 from "../../assets/pro/pro4.jpg";
import bgdetail from "../../assets/images/resource/project-detail.jpg";
const ProjectDetails = () => {
  return (
    <Fragment>
      <div
        id="content_hero"
        className="center-content hero-ontop"
        style={{
          backgroundImage: `url(${bgproject})`,
        }}
      >
        <div className="container">
          <div
            className="row blurb scrollme animateme"
            data-when="exit"
            data-from="0"
            data-to="1"
            data-opacity="0"
            data-translatey="100"
          >
            <div className="col-md-9">
              <span className="title">Romming Villa</span>
              <h1>Southward Concert Hall</h1>
              <p>
                We deliver reliable architectural solutions to our individual
                and corporate clients throughout the USA.
              </p>
            </div>
          </div>
        </div>
      </div>
      <section className="section section-lg  text-center text-sm-left">
        <div className="container">
          <div className="row row-40">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-4">
                  <img src={pro4} alt="sad" />{" "}
                </div>
                <div className="col-4">
                  <img src={pro2} alt="sad" />
                </div>
                <div className="col-4">
                  <img src={pro3} alt="sad" />
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="row row-30 row-xxl-85">
                <div className="col-sm-6 col-md-4">
                  <h5 className="text-black">Architecture</h5>
                  <ul className="list-xs font-weight-regular">
                    <li>
                      <a className="link-item textDark" href="#">
                        Planning & Development
                      </a>
                    </li>
                    <li>
                      <a className="link-item textDark" href="#">
                        Project Management
                      </a>
                    </li>
                    <li>
                      <a className="link-item textDark" href="#">
                        Structural Engineering
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-6 col-md-4">
                  <h5>3D Modeling</h5>
                  <ul className="list-xs font-weight-regular">
                    <li>
                      <a className="link-item textDark" href="#">
                        Interior Rendering
                      </a>
                    </li>
                    <li>
                      <a className="link-item textDark" href="#">
                        Visualization
                      </a>
                    </li>
                    <li>
                      <a className="link-item textDark" href="#">
                        VR Architecture
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-6 col-md-4">
                  <h5>Design</h5>
                  <ul className="list-xs font-weight-regular">
                    <li>
                      <a className="link-item textDark" href="#">
                        Interior Design
                      </a>
                    </li>
                    <li>
                      <a className="link-item textDark" href="#">
                        Architectural Design
                      </a>
                    </li>
                    <li>
                      <a className="link-item textDark" href="#">
                        Technical Detailing
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-6 col-md-4">
                  <article className="box-counter">
                    <div className="box-counter-main">
                      <div className="counter">10</div>
                      <span className="small small_top">+</span>
                    </div>
                    <div className="box-counter-title">
                      International awards
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-md-4">
                  <article className="box-counter">
                    <div className="box-counter-main">
                      <div className="counter">57</div>
                    </div>
                    <div className="box-counter-title">Finished projects</div>
                  </article>
                </div>
                <div className="col-sm-6 col-md-4">
                  <article className="box-counter">
                    <div className="box-counter-main">
                      <div className="counter">19</div>
                    </div>
                    <div className="box-counter-title">Years of experience</div>
                  </article>
                </div>
              </div>
            </div>
            <div className="col-lg-3 d-flex flex-column justify-content-between">
              <div className="heading-3">High-quality solutions</div>
              <div className="big text-white-lighter text-white-darken textDark">
                We are committed to providing the best possible solutions at a
                competitive cost. Our team is ready to offer a wide range of
                various architectural services.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cta-section pt100 pb50 ">
        <div className="cta-image-box"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-7 pl-lg-0 offset-lg-5 cta-content">
              <h2 className="sp-title">
                Dare to dream of a modern <span>home</span>
              </h2>
              <p>
                Pellentesque lorem dolor, malesuada eget tortor vitae, tristique
                lacinia lectus. Pellentesque sed accumsan risus, id aliquam
                nulla. Integer lorem risus, feugiat at mauris malesuada,
                accumsan pellentesque ipsum. Nunc dapibus, libero ut pulvinar
                accumsan, tortor nisl iaculis ligula. Curabitur finibus dolor
                vel lectus pretium interdum a eget ante.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
export default ProjectDetails;
