import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import logo from "../../assets/images/clients/3.png";
// import img1 from "../../assets/logo/icons8-united-kingdom-48.png";
// import img2 from "../../assets/logo/icons8-egypt-48.png";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../store/language/lanSlicer";
import { baseUrl } from "../../utils/APi";
import { getSocialMedia } from "../../store/contact/contactAction";
// const languages = [
//   {
//     code: "en",
//     name: "English",
//     dir: "ltr",
//     image: img2,
//   },
//   {
//     code: "ar",
//     name: "العربية",
//     dir: "rtl",
//     image: img1,
//   },
// ];


const SideNav = ({ width, closeNav }) => {
    const sideRef = useRef(null);
    const [openDropdown, setOpenDropdown] = useState(null);

    const handleDropdownToggle = (index) => {
        setOpenDropdown((prevIndex) => (prevIndex === index ? null : index));
      };
      
    const menuItems = [
        {
          title: "Home",
          link: "/",
          submenu: [
            { title: "Home Showcase", link: "/" },
            { title: "Home Carousel", link: "/" },
            { title: "Home Banner", link: "/" },
          ],
        },
        {
          title: "About",
          link: "/about",
          submenu: [
            { title: "About Us", link: "/about" },
            { title: "about Me", link: "/about" },
            { title: "FAQ's", link: "/about" },
            { title: "Team", link: "/about" },
            { title: "Pricing", link: "/about" },
            { title: "Coming Soon", link: "/about" },
          ],
        },
        {
          title: "Services",
          link: "/services",
          submenu: [
            { title: "All Services", link: "/services" },
            { title: "Civil Engineering", link: "/services" },
            { title: "Oil & Gas Engineering", link: "/services" },
            { title: "Chemical Engineering", link: "/services" },
            { title: "Agriculture Engineering", link: "/services" },
            { title: "Mechanical Engineering", link: "/services" },
            { title: "Service Detail", link: "/services" },
          ],
        },
        {
          title: "Projects",
          link: "/projects",
          submenu: [
            { title: "Project with Filters", link: "/projects" },
            { title: "Project Slide Show", link: "/projects" },
            { title: "Project Detail", link: "/projects" },
          ],
        },
        {
          title: "Blog",
          link: "/blog",
          submenu: [
            { title: "Blog with Sidebar", link: "/blog" },
            { title: "Blog Grid View", link: "/blog" },
            { title: "Blog Single", link: "/blog" },
            { title: "Error Page", link: "/blog" },
          ],
        },
       
        { title: "Contact", link: "/contact" },
      ];

      // const dispatch = useDispatch();
  // const { t, i18n } = useTranslation();
  // const currentLanguageCode = Cookies.get("i18next") || "en";
  // const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  // const changeLanguage = (languageCode) => {
  //   i18n.changeLanguage(languageCode);
  //   Cookies.set("i18next", languageCode);
  // };
  // const [show, setShow] = useState(false);
  // const toggleLanguage = () => {
  //   const newLanguageCode = currentLanguage.code === "en" ? "ar" : "en";
  //   document.body.dir = newLanguageCode === "ar" ? "rtl" : "ltr";
  //   changeLanguage(newLanguageCode);
  //   dispatch(setLanguage(newLanguageCode));
  // };
  // const { SocialMedia } = useSelector((state) => state.contactSlice);

  // useEffect(() => {
  //     dispatch(getSocialMedia());
  // }, [dispatch]);
 
//   useEffect(() => {
//     function handleClickOutside(event) {
//       if (sideRef.current && !sideRef.current.contains(event.target)) {
//         Close();
//       }
//     }
//     window.addEventListener("click", handleClickOutside);
//     return () => {
//       window.removeEventListener("click", handleClickOutside);
//     };
//   }, [Close]);
  
return (
    <section
    className={`sidenav-bar ${width > 0 ? "sidenav-bar-visible" : ""}`}
    style={{ width: width + "px" }}
    ref={sideRef}
  >
    <div className="inner-box">
      <button className="close-btn" onClick={closeNav}>
        <span className="fa fa-times"></span>
      </button>
      <Link to={"/"}>
                  <img src={logo} alt="" title="" className="side-logo" />
                </Link>
      <nav className="side-nav mb-5">
        <ul className="navigation clearfix" id="nav">
          {menuItems.map((item, index) => (
            <li key={index} className={`dropdown ${openDropdown === index ? "active" : ""}`}>
              <Link to={item.link} onClick={closeNav}>{item.title}</Link>
              {item.submenu && (
                <>
                  <span
                    className={`dropdown-btn ${openDropdown === index ? "active" : ""}`}
                    onClick={() => handleDropdownToggle(index)}
                  >
                    <i className="fa-solid fa-plus"></i>
                  </span>
                  <ul className={openDropdown === index ? "expanded" : ""}>
                    {item.submenu.map((subItem, subIndex) => (
                      <li key={subIndex}>
                        <Link onClick={closeNav} to={subItem.link}>{subItem.title}</Link>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </li>
          ))}
        </ul>
      </nav>
      <div className="contact-info-box">
        <div className="info-box">
          <div className="icon-box">
            <span className="fa fa-home"></span>
          </div>
          <p>
            13005 Greenville Avenue
            <br />
            United Kingdom, TX 70240
          </p>
        </div>
        <div className="info-box">
          <div className="icon-box">
            <span className="far fa-clock"></span>
          </div>
          <p>Mon - Fri: 09:00 - 18:00</p>
          <p>Sunday --- Close</p>
        </div>
        <div className="info-box">
          <div className="icon-box">
            <span className="far fa-envelope"></span>
          </div>
          <p>
            <a href="tel:(888)123456789">(888) 123 456 789</a>
          </p>
          <p>
            <a href="mailto:support@example.com">support@example.com</a>
          </p>
        </div>
      </div>
    </div>
  </section>
  
);
};
  
  export default SideNav;
