import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { baseUrl } from "../../utils/APi";
import { getGallery } from "../../store/home/homeAction";
import { LazyLoadImage } from "react-lazy-load-image-component";

import img1 from "../../assets/icon/light/3.png";
import img2 from "../../assets/icon/light/1.png";
import img3 from "../../assets/icon/light/2.png";

const Dream = () => {
  // const dispatch = useDispatch();
  // const { t } = useTranslation();
  // const lan = useSelector((state) => state.LanSlicer.language);
  // const { gallery } = useSelector((state) => state.homeSlicer);
  // useEffect(() => {
  //   if (!gallery?.length) {
  //   const promise=  dispatch(getGallery());
  //   return () => {
  //     promise.abort();
  //   };
  //   }
  // }, [lan, dispatch, gallery?.length]);

  return (
    <section className="cta-section pt100 pb50">
      <div className="cta-image-box"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-7 pl-lg-0 offset-lg-5 cta-content">
            <h2 className="sp-title">
              Dare to dream of a modern <span>home</span>
            </h2>
            <p>
              Pellentesque lorem dolor, malesuada eget tortor vitae, tristique
              lacinia lectus. Pellentesque sed accumsan risus, id aliquam nulla.
              Integer lorem risus, feugiat at mauris malesuada, accumsan
              pellentesque ipsum. Nunc dapibus, libero ut pulvinar accumsan,
              tortor nisl iaculis ligula. Curabitur finibus dolor vel lectus
              pretium interdum a eget ante.{" "}
            </p>
            <div className="cta-icons">
              <div className="cta-img-icon">
                <img src={img1} alt="" />
              </div>
              <div className="cta-img-icon">
                <img src={img2} alt="" />
              </div>
              <div className="cta-img-icon">
                <img src={img3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dream;
