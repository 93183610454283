// import { useEffect, useState } from "react";

// import { Link } from "react-router-dom";
// import { useTranslation } from "react-i18next";
// import { useDispatch, useSelector } from "react-redux";
// import TopHeader from "./TopHeader";
// import { MobileHeader } from "./MobileHeader";
// import SideNav from "./SideNav";
// import ShopingCart from "./ShopingCart";
// import { getcategory } from "../../store/products/productAction";
// import Search from "./Search";
// import { getLogo } from "../../store/cart/cartAction";
// import { baseUrl } from "../../utils/APi";

import { useState } from "react";
import { Link } from "react-router-dom";
import SideNav from "./SideNav";
import Search from "./Search";
import Services from "./../../pages/services/Services";

const Header = () => {
  const [navWidth, setNavWidth] = useState(0);

  const openNav = () => {
    setNavWidth(380);
  };
  const closeNav = () => {
    setNavWidth(0);
  };

  // const dispatch = useDispatch();
  // const { t } = useTranslation();
  // const { category } = useSelector((state) => state.Productslice);
  // const lan = useSelector((state) => state.LanSlicer.language);
  // const { cart, favlist, logo } = useSelector((state) => state.cartSlice);

  // const openCart = () => {
  //   setCartWidth(true);
  // };
  // const closeCart = () => {
  //   setCartWidth(false);
  // };
  // useEffect(() => {
  //   dispatch(getcategory(lan));
  //   if(!logo){
  //     const promise = dispatch(getLogo());
  //     return () => {
  //       promise.abort();
  //     };
  //   }
  // }, [lan, dispatch]);
  return (
    <section className="section section-relative section-header">
      <header className="section page-header header-absolute">
        <div className="rd-navbar-wrap">
          <nav className="rd-navbar rd-navbar-aside rd-navbar-static">
            <div className="rd-navbar-collapse-toggle rd-navbar-fixed-element-1 rd-navbar-collapse">
              <span></span>
            </div>
            <div className="rd-navbar-collapse">
              <ul className="list rd-navbar-list">
                <li>
                  <a
                    className="icon icon-sm icon-bordered link-default mdi mdi-behance"
                    href="#"
                  ></a>
                </li>
                <li>
                  <a
                    className="icon icon-sm icon-bordered link-default mdi mdi-facebook"
                    href="#"
                  ></a>
                </li>
                <li>
                  <a
                    className="icon icon-sm icon-bordered link-default mdi mdi-instagram"
                    href="#"
                  ></a>
                </li>
              </ul>
            </div>
            <div className="rd-navbar-main-outer">
              <div className="rd-navbar-main">
                <div className="rd-navbar-panel">
                  <button className="rd-navbar-toggle rd-navbar-nav-wrap">
                    <span></span>
                  </button>
                  <div className="rd-navbar-brand">
                    <a className="brand" href="index.html">
                      <img
                        className="brand-logo-dark"
                        src="images/logo-default-465x134.png"
                        alt=""
                        width="232"
                        height="67"
                      />
                      <img
                        className="brand-logo-light"
                        src="images/logo-inverse-86x104.png"
                        alt=""
                        width="86"
                        height="104"
                      />
                    </a>
                  </div>
                </div>
                <div className="rd-navbar-nav-wrap">
                  <ul className="rd-navbar-nav">
                    <li className="rd-nav-item active">
                      <Link className="rd-nav-link" to={"/"}>
                        Home
                      </Link>
                    </li>
                    <li className="rd-nav-item">
                      <Link className="rd-nav-link" to={"/about"}>
                        About
                      </Link>
                    </li>
                    <li className="rd-nav-item">
                      <Link className="rd-nav-link" to={"/projects"}>
                        projects
                      </Link>
                    </li>
                    <li className="rd-nav-item">
                      <Link className="rd-nav-link" to={"/services"}>
                        Services
                      </Link>
                    </li>
                    <li className="rd-nav-item">
                      <Link className="rd-nav-link" to={"/blog"}>
                        Blog
                      </Link>
                    </li>
                    <li className="rd-nav-item">
                      <Link className="rd-nav-link" to={"/contact"}>
                        Contacts
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </section>
  );
};

export default Header;
